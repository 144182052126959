/*!
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
@use '@angular/material' as mat;

@import '@opengamma/ui/src/styles/_globals';

@import 'mat-theme';

@include mat.all-component-typographies();
@include mat.core();
@include mat.all-component-themes(mat.$m2-blue-gray-palette);

body {
  background-color: rgba($gallery, 0.5);
}

html,
body {
  overflow-x: auto;
  margin-right: 0;
  height: auto;
  min-width: auto;
}

.horizontal-divider {
  background: $gallery;
  height: 1px;
  width: 15%;
}
